
@for $i from 1 through 100 {
  .vh#{$i} {
    height: #{$i}vh;
  }
}

.h100Percent {
  height: 100% !important;
}
