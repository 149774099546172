@import 'common';
@import 'mixins';
@import 'variables';

.faq-wrap{
    .faq-section{
        background-image: url(../../../assets/images/faq/faq-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        padding: 4rem;
        background-color: rgba(255, 255, 255, 0.8);
        .content{
            .faq-block{
                max-width: 960px;
                margin: 0 auto;
                .message-row{
                    border: 1px solid $silver;
                    margin: 1rem;
                    padding: 1rem;
                    background: $white;
                    @include box_shadow;
                    .message-block{
                        .user{
                            display: flex;
                            align-items: center;
                            img{
                                width: 30px;
                            }
                            span{
                                padding: 0.4rem 0.6rem;
                            }
                        }
                        .message{
                            padding: 0.5rem 0;
                            p{
                                text-align: justify;
                            }
                        }
                    }
                    .answer{
                        border-top: 1px solid $silver;
                        padding: 0.5rem 0 0 1rem;
                        .user{
                            span{
                                color: $default1;
                            }
                        }
                        .message{
                            p{
                                font-style: italic;
                                color: $default1;
                            }
                        }
                    }
                }
            }
        }
    }
}
