@import 'variables';

$toggleColor: #8BA0BA;

.sidebar {
  display: flex;
  flex-direction: column;
  width: 330px;
  background-color: $gray-sidebar;
  overflow: auto;
  transition: width 1s;
  &-toggle {
    display: none;
    flex-grow: 1;
    width: 100%;
    color: $toggleColor;
    text-align: center;
    font-size: 40px;
    margin-top: 15px;
  }
  &-results-btn {
    // background-color: #0093ee;
    padding: 3px 0;
    border-top: 1px solid $toggleColor;
    border-bottom: 1px solid $toggleColor;
    cursor: pointer;
    // color: white;
    // border-radius: 5px;
  }
  &-results {
    display: flex;
    justify-content: space-between;
    align-content: center;
    color: white;
    padding: 5px;
    padding-bottom: 0px;
    margin-top: 10px;
    align-items: center;
    span:first-child {
      visibility: hidden;
    }
    span:nth-child(2) {
      cursor: pointer;
      border-radius: 5px;
      padding: 5px 15px;
      background-color: #0093ee;
      text-transform: uppercase;
      line-height: 2;
    }
  }
  .sidebar-similarity {
    max-width: 50px;
    padding-left: 10px;
    &-head, &-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;
      padding-top: 10px;
      height: 75px;
      color: #fff;
      font-size: 10px;
      text-align: center;
    }
    &-head {
      padding-top: 0px;
    }
    &-bottom {
      height: auto !important;
    }
    &-triangle {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 10%;
        background: white;
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
      }
    }
  }
  &-main {
    width: 100%;
    &-head {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75px;
      padding: 0px 10px 14px 10px;
      color: #fff;
      text-align: center;
      span:first-child {
        height: 100%;
        .a, .b {
          align-items: center;
          height: 100%;
        }
        .a {
          display: flex;
        }
        .b {
          display: none;
        }
        &:hover {
          .a {
            display: none;
          }
          .b {
            display: flex;
          }
        }
      }
      span:last-of-type {
        color: $toggleColor;
      }
    }
  }
  &-culture {
    display: flex;
    align-items: center;
    color: #fff;
    &-selected {
      background-color: #1a2536;
    }
    &-cont {
      display: flex;
      align-items: center;
      padding: 14px 20px 14px 10px;
      height: 75px;
      font-size: 0.8rem;
      box-sizing: border-box;
      cursor: pointer;
    }
    &-icon {
      width: 45px;
      margin-right: 5px;
    }
    &-btn {
      display: none;
      padding: 5px;
      color: white;
      background-color: #4781dd;
      border-radius: 4px;
      border-style: none;
      border-color: none;
      font-size: 13px;
      cursor: pointer;
    }
    &-country {
      font-size: 10px;
    }
  }
  &-collapsed:not(.sidebar-mob) {
    width: 65px;
    display: block;
    overflow: hidden;
    .sidebar-similarity {
      display: none;
    }
    .sidebar-main {
      display: none;
    }
    .sidebar-toggle {
      display: flex;
    }
    .sidebar-results {
      display: none;
    }
  }
}



@media only screen and (min-width: 600px) {
  .sidebar-culture-cont:hover {
    background-color: #1a2536;
    // display: block;
  }

  .sidebar-culture-cont:hover .sidebar-culture-info {
    background-color: #1a2536;
    // display: none;
  }

}

@media only screen and (max-width: 600px) {
  .sidebar {
    display: none;
    &-results {
      margin-bottom: 15px;
      margin-top: 0px;
      padding: 0;
    }
  }

  .sidebar-mob {
    margin: 0 20px 40px 20px;
    display: flex;
    width: calc(100% - 40px);
    background-color: #fff;
    .sidebar-similarity, .sidebar-main-head {
      display: none;
    }
    .sidebar-culture-cont {
      border-top: #b2b2b2 solid 1px;
      padding: 10px 10px 10px 10px;
      border-radius: 4px;
    }
    .sidebar-culture-cont:last-of-type {
      border-bottom: #b2b2b2 solid 1px;
    }
    .sidebar-culture {
      color: #b2b2b2;
    }
  }
  /* #end region sidebar mobile*/
}
