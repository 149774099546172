.figure {
	font-family: 'Nunito', sans-serif;
	display: flex;
	justify-content: center;
	padding: $main-spacing/2;
	align-items: center;
	text-transform: uppercase;
	font-weight: 500;
	flex-wrap: wrap;
	text-align: center;
	border-radius: 5px;
	background-color: $white;
	box-shadow: 0 0 7px -2px $color-light-grey;
	transition: $transition;

	&:hover {
		box-shadow: 0 0 11px -2px $color-light-grey;
		cursor: pointer;
	}
}
