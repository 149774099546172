@import 'mixins';

.map {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  z-index: 1;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0;
  position: relative;
}

.leaflet-control-attribution.leaflet-control {
  display: none !important;
  height: 0;
}

.leaflet-overlay-pane {
  margin-top: -50000px;
  margin-left: -50000px;
  width: calc(100000px + 100%);
  height: calc(100000px + 100%);
  background-color: rgba(0, 112, 206, 0.7);
  z-index: 51 !important;
}
.leaflet-pane {
  z-index: 50 !important;
}

.leaflet-pane > svg path.leaflet-interactive {
  z-index: 50;
}

.leaflet-control-zoom {
  padding: 7px !important;
  border-radius: 20px !important;
  background-color: rgba(168, 198, 223, 0.6);
  -webkit-box-shadow: 1px 1px 1px 0 rgba(28, 41, 59, 0.075),
    1px 1px 4px 0 rgba(28, 41, 59, 0.05);
  box-shadow: 1px 1px 1px 0 rgba(28, 41, 59, 0.075),
    1px 1px 4px 0 rgba(28, 41, 59, 0.05);
  & > a {
    color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-radius: 100px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: rgba(37, 55, 79, 0.4);
    -webkit-box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    &:hover,
    &:active {
      background-color: #1c293b;
    }
    &:active {
      -webkit-transform: scale(0.925);
      transform: scale(0.925);
    }
    &:nth-child(2) {
      margin-top: 7px;
    }
  }
  & > a:not(.leaflet-disabled) {
    background: rgba(37, 55, 79, 0.9);
    -webkit-box-shadow: 1px 1px 1px 0 rgba(32, 33, 35, 0.09),
      1px 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 1px 1px 1px 0 rgba(32, 33, 35, 0.09),
      1px 1px 4px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: -webkit-transform 0.15s ease-in-out;
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    cursor: pointer;
  }
}

.marker-cluster-small {
  background-color: transparent;
  // background-color: #fff;
  // animation: ripple 2s linear infinite;
  div {
    background-color: white;
    color: #1c293b;
    font-weight: bold;
  }
}

.custom-maker-icon {
  // width: 16px;
  // height: 16px;
  border-radius: 100%;
  background-color: white;
  animation: ripple 2s ease-out infinite;
  z-index: 140 !important;
}

.leaflet-marker-pane img {
  z-index: 170;
}

img.leaflet-marker-icon {
  z-index: 1060 !important;
}

@media only screen and (max-width: 600px) {
  .map {
    margin-bottom: 20px;
    // height: calc(100vh - 140px);
    height: 50vh;
  }
  .leaflet-control-zoom {
    display: none;
  }
}

.icon-opacity {
  opacity: 0.5 !important;
}


$nbItems: 20;

@for $i from 1 through $nbItems {
  .marker-cluster-circle-#{$i} {
    @media only screen and (min-width: 601px) {
      @include on-circle($item-count: $i, $circle-size: 25px, $item-size: 40px);
      img {
        width: 40px;
      }
    }
    @media only screen and (max-width: 60px) {
      @include on-circle($item-count: $i, $circle-size: 25px, $item-size: 32px);
      img {
        width: 32px;
      }
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
}
