
@import 'variables';

$black: #000;
$gray-darkest: #121111;
$gray-darker: #3b3643;
$gray-dark: lighten($gray-darkest, 30);

//Common styles
*{margin:0; padding:0}

body{font-family: roboto, sans-serif;}

.col-25{width: 25%}
.col-50{width: 50%}
.col-75{width: 75%}
.col-100{width: 100%}

.desktop-hide{display: none;}
.mobile-hide{display:block;}

ul{ margin: 0; padding: 0;
    li{list-style: none;}
}

a{text-decoration: none !important;}
a:active{color: $default2;}
a:focus{outline: none;}

//Pages styles
.page-wrap{
    .content-row{
        padding: 4rem;
        .content-block{
            max-width: 960px;
            margin: 0 auto;
            .icon{
                max-width: 300px;
                margin: 0 auto;
                img{
                    width: 100%;
                }
            }
            .title{
                text-align: center;
                padding: 1rem;
                font-size: 1.5rem;
                text-transform: uppercase;
                color: $default2;
                font-weight: 500;
            }
            .description{
              text-align: left;
              font-size: 16px;
              display: block;
              margin-bottom: 15px;
              color: $gray-dark;
              line-height: 1.8;
              // text-align: justify;
              h2 {
                text-transform: uppercase;
              }
              img {
                margin: auto;
                display: block;
                max-width: 100%;
                max-height: 600px;
              }
              legend {
                color: #aaa9ad;
                // line-height: 16px;
                font-size: 12px;
                display: block;
                margin: auto;
                text-align: center;
              }
            }
        }
        &:nth-child(even){
            background: $section-bg;
        }
    }
    .navbar_wrap{
        .logo{
            a{
                &:hover{
                    color: $default1;
                }
            }
        }
    }
}

//Genepools test cluster color code
  @each $clusterColor in (
          clusterRed #c61c1c,
          clusterPurple #ac4eed,
          clusterBlue #5ccefb,
          clusterGreen #99e985,
          clusterGold #f1bd68
  ) {
    .#{nth($clusterColor,1)} {
      background-color: #{nth($clusterColor,2)};
    }
  }
