// Set the number of columns you want to use on your layout.
$grid-columns: 12 !default;
// Set the gutter between columns.
$gutter-width: 1rem !default;
// Set a margin for the container sides.
$outer-margin: 0 !default;
// Create or remove breakpoints for your project
// Syntax:
// name SIZErem,
$breakpoints:
sm 48em 46rem,
md 62em 61rem,
lg 75em 71rem !default;
$flexboxgrid-max-width: 1200px !default;

//
// -- Stop editing -- //
//

$gutter-compensation: $gutter-width * .5 * -1;
$half-gutter-width: 0; //$gutter-width * .5;

.row {
  box-sizing: border-box;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);

  &--resp-spacing {
    [class^="col-"]:not(:last-child) {
      @include media-max-md {
        margin-bottom: 40px;
      }

      @include media-max-sm {
        margin-bottom: $spacing-responsive;
      }
    }
  }

  &--padding {
    [class^="col-"],.row-col {
      padding: $card-padding/2;
    }

    .row-col {
        max-width: 100%;
    }

    margin: -$card-padding/2;
  }

  &--side-padding {
    [class^="col-"] {
      padding: 0 $card-padding/2;
    }

    margin: 0 -$card-padding/2;
  }

  &--card-padding {
    [class^="col-"] {
      padding: 0 20px;
    }

    margin: 0 -20px;

    @include media-max-md {
      [class^="col-"] {
        padding: 0 15px;
      }

      margin: 0 -15px;
    }
  }

  &.padding-correct {
    margin: -$card-padding/2 -$card-padding/2 0 -$card-padding/2;
  }
}

.col {
  box-sizing: border-box;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(column);
}

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual props
  @include flex-grow(0);
  @include flex-shrink(0);

  // we leave @include flex-basis(auto) out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126
}

$name: xs;
.col-#{$name} {
  @include flexboxgrid-sass-col-common;
  @include flex-basis(auto);
}
@for $i from 1 through $grid-columns {
  .col-#{$name}-#{$i} {
    @include flexboxgrid-sass-col-common;
    @include flex-basis(100% / $grid-columns * $i);
    width: 100% / $grid-columns * $i;
  }
}
@for $i from 0 through $grid-columns {
  .col-#{$name}-offset-#{$i} {
    @include flexboxgrid-sass-col-common;
    @if $i == 0 {
      margin-left: 0;
      } @else {
        margin-left: 100% / $grid-columns * $i;
      }
    }
  }
  .row-#{$name}-reverse {
    @include flex-direction(row-reverse);
  }
  .col-#{$name}-reverse {
    @include flex-direction(column-reverse);
  }
  .col-#{$name} {
    @include flex-grow(1);
    @include flex-basis(0);
    max-width: 100%;
  }
  .start-#{$name} {
    @include justify-content(flex-start);
    text-align: start;
  }

  .center-#{$name} {
    @include justify-content(center);
    text-align: center;
  }

  .end-#{$name} {
    @include justify-content(flex-end);
    text-align: end;
  }

  .top-#{$name} {
    @include align-items(flex-start);
  }

  .middle-#{$name} {
    @include align-items(center);
  }

  .bottom-#{$name} {
    @include align-items(flex-end);
  }

  .around-#{$name} {
    @include justify-content(space-around);
  }

  .between-#{$name} {
    @include justify-content(space-between);
  }

  .stretch-#{$name} {
    @include align-items(stretch);
  }

  .first-#{$name} {
    order: -1;
  }

  .last-#{$name} {
    order: 1;
  }


  @each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);
    $container: nth($breakpoint, 3);
    @media only screen and (min-width: $size) {
      .flex-container {
        width: $container;
      }

      .col-#{$name} {
        @include flexboxgrid-sass-col-common;
        @include flex-basis(auto);
      }
      @for $i from 1 through $grid-columns {
        .col-#{$name}-#{$i} {
          @include flexboxgrid-sass-col-common;
          @include flex-basis(100% / $grid-columns * $i);
          width: 100% / $grid-columns * $i;
        }
      }
      @for $i from 0 through $grid-columns {
        .col-#{$name}-offset-#{$i} {
          @include flexboxgrid-sass-col-common;
          @if $i == 0 {
            margin-left: 0;
            } @else {
              margin-left: 100% / $grid-columns * $i;
            }
          }
        }
        .col-#{$name} {
          @include flex-grow(1);
          @include flex-basis(0);
          max-width: 100%;
        }
        .row-#{$name}-reverse {
          @include flex-direction(row-reverse);
        }
        .col-#{$name}-reverse {
          @include flex-direction(column-reverse);
        }
        .start-#{$name} {
          @include justify-content(flex-start);
          text-align: start;
        }

        .center-#{$name} {
          @include justify-content(center);
          text-align: center;
        }

        .end-#{$name} {
          @include justify-content(flex-end);
          text-align: end;
        }

        .top-#{$name} {
          @include align-items(flex-start);
        }

        .middle-#{$name} {
          @include align-items(center);
        }

        .bottom-#{$name} {
          @include align-items(flex-end);
        }

        .around-#{$name} {
          @include justify-content(space-around);
        }

        .between-#{$name} {
          @include justify-content(space-between);
        }

        .stretch-#{$name} {
          @include align-items(stretch);
        }

        .first-#{$name} {
          order: -1;
        }

        .last-#{$name} {
          order: 1;
        }
      }
    }
