$text-sizes: (
	5: 5px,
	10: 10px,
	12: 12px,
	13: 13px,
	15: 15px,
	20: 20px,
	25: 25px,
	30: 30px,
	40: 40px,
	96: 96px,
);

.t {
	&-c {
		text-align: center;
	}
	&-l {
		text-align: left;
	}
	&-justify {
		text-align: justify !important;
	}
	&-b {
		font-weight: bold;
	}
	&-fwu {
		font-weight: normal;
	}
	&-400 {
		font-weight: 400;
	}
	&-b,
	&-400 {
		&[class^='icon-'],
		&[class*=' icon-'] {
			&:before,
			&:after {
				font-weight: bold;
			}
		}
	}
	@each $name, $value in $text-sizes {
		&-#{$name} {
			font-size: $value;
		}
	}

	@each $name, $color in $colors {
		&-#{$name} {
			color: $color;
		}
	}

	@include media-max-sm {
		&-xs {
			font-size: 20px;
		}
	}

	&--ellipsis {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&--ellipsis-only {
		text-overflow: ellipsis;
		overflow: hidden;
	}
	&--uppercase {
		text-transform: uppercase;
	}
	&--lowercase {
		text-transform: lowercase;
	}
	&--underline {
		text-transform: underline;
	}
}

@mixin link {
	color: $color-second;
	font-weight: bold;
	cursor: pointer;
}

.link {
	@include link;
}

.link-before-after {
  &:before, &:after {
		@include link;
	}
}

.label, .label-value {
	padding-bottom: 5px;
	font-size: 14px;
	color: #a4abb3;
}

.label-value {
	color: $color-text-dark;
}

.pre-line {
	white-space: pre-line;
}

.nowrap {
	white-space: nowrap;
}
