@import 'mixins';
// Media device manupulations
//========================***

// Media device up to 2500px
@media only screen and (max-width:2500px){

}

//Media tab
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .navbar_wrap {
        padding: 1rem 2rem;
        .logo{
            a{
                font-size: 21px;
            }
        }
        .menu {
            .nav_right {
                ul {
                    li {
                        padding: 0 1rem;
                    }
                }
            }
        }
    }
}

// Media small devices max-767
@media only screen and(max-width:767px){

    //Hidden and show class
    .desktop-hide{
        display: block;
    }

    .mobile-hide{
        display: none;
    }

    //Navbar
    //=====================***
    .navbar_wrap{
        padding: 1rem;
        width: auto;
        .logo{
            width: 90%;
            a{
                font-size: 21px;
            }
        }
        .menu{
            width:10%;
            .nav_right{
                .icon{
                    position: relative;
                    button{
                        border: 1px solid #b6e2f5;
                        padding: 4px;
                        background: transparent;
                        border-radius: 2px;
                        &:focus{
                            outline: none;
                        }
                        span{
                            width: 24px;
                            height : 2px;
                            background: $white;
                            display: block;
                            margin: 4px 0;
                            &:nth-child(1){
                                margin-top:0;
                            }
                            &:nth-child(2){
                                width: 22px;
                                margin-left: 2px;
                            }
                            &:nth-child(3){
                                width: 20px;
                                margin-left: 4px;
                            }
                            &:nth-child(4){
                                width: 22px;
                                margin-left: 2px;
                                margin-bottom: 0;
                            }
                        }
                    }
                    .mobile-menu{
                        position: absolute;
                        right: 0;
                        background: $default1;
                        text-align: left;
                        padding: 1rem;
                        min-width: 220px;
                        @include box-shadow;
                        li{
                            padding:1rem 0;
                            border-bottom: 1px solid #c6e6f3;
                            display: block;
                            a{
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    //Homepage
    //=====================***
    .homepage-banner{
        background-position: center;
        padding-bottom: 10%;
        .welcome-block{
            min-height: auto;
            .welcome-text{
                padding: 2rem 0;
                .title{
                    font-size: 3rem;
                    max-width: 300px;
                    margin: 0 auto;
                    text-align: center;
                }
                .sub-title{
                    font-size: 1.5rem;
                }
            }
        }
    }

    //About
    .about-wrap{
        padding: 3rem 1rem;
        background-position: 50% center;
        .content{
            .icon{
                img{
                    max-width: 250px;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
    }

    //Package
    .package-wrap{
        .content{
            .package-col{
                width: 90%;
                padding: 2rem 1rem 3rem;
                &:first-child{
                    margin-right:0;
                }
                &:last-child{
                    margin-left:0;
                }
                .title{
                    text-align: center;
                }
                &:last-child{
                    margin-top: 1rem;
                }
            }
        }
    }

    //Footer
    .footer-wrap{
        .content{
            padding: 1rem;
            display: block;
            .footer-col{
                width: 100%;
                padding: 2rem 0;
                text-align: center;
                padding-right: 0;
                border-bottom: 1px solid #2d3b56;
                &:first-child{
                    padding: 2rem 0;
                }
                &:last-child{
                    padding-bottom: 0;
                    border-bottom: none;
                }
                .title{
                    text-align: center;
                }
            }
        }
    }

    //Pages
    .page-wrap{
        .navbar_wrap {
            .menu {
                .nav_right {
                    .icon {
                        button {
                            span{
                                background: $default1;
                            }
                        }
                    }
                }
            }
        }
        .content-row{
            padding: 1rem;
            .content-block{
                .icon{
                    max-width: 250px;
                }
            }
            .content-block{
                .description{
                    text-align: left;
                }
            }
        }
    }

    //Faq
    .faq-wrap {
        .faq-section{
            padding: 1rem;
            .content{
                .faq-block{
                    .message-row{
                        margin: 1rem 0;
                    }
                }
            }
        }
    }

    //Page header
    .page-header-wrap{
        background-position: 24% center;
    }
}

// Media small devices max-320
@media only screen and(max-width:320px){

    //Package
    .package-wrap{
        padding: 0.7rem;
        .content{
            .package-col{
                width: 92%;
                padding: 2rem 0.7rem;
            }
        }
    }
}
