@import '../../variables';
@import 'common';
@import 'mixins';
@import 'variables';


.navbar_wrap{
  &.blue {
    background-color: #4584CA;
  }
  display: flex;
  padding: 1rem 5rem;
  align-items: center;
  .logo {
    width: 30%;
    color: $gray-sidebar;
    a {
      color: $gray-sidebar;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .menu{
    width: 70%;
    .nav_right{
      float: right;
      text-align: right;
      .icon {      }
      ul{
        li{
          padding: 0 2rem;
          display: inline-block;
          a{
            color: $default2;
            font-size:18px;
            text-transform: uppercase;
            &:hover{
              color: $default1;
              transition: 0.7s ease-in-out;
            }
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }
    }
  }
}
