@import 'variables';

.section-text {
    p {
      text-align: left;
      font-size: 16px;
      display: block;
      margin-bottom: 15px;
      color: $gray-dark;
      line-height: 1.8;
      a {
        color: #0eb1d2;
      }
    }
    h4 {
      margin: 35px 0 15px;
      color: $brand-primary;
      font-size: 22.5px;
      font-weight: 700;
    }
    h5 {
      margin: 25px 0 15px;
      color: $gray-darker;
      font-size: 16.5px;
    }
  }

.section-text-toc {
  display: block;
  padding: 0;
  margin: 0 0 50px;
  list-style: none;
  border-top: 1px solid $base-border-color;
  li {
    display: block;
    display: block;
    padding: 10px 0;
    color: $brand-info;
    border-bottom: 1px solid $base-border-color;
    cursor: pointer;
  }
}
