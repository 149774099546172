@import 'common';
@import 'mixins';
@import 'variables';

//Page header
.page-header-wrap{
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center;
    .content{
        .page-title{
            h1{
                color: $white;
                span{
                    display: block;
                    width:80%;
                    height: 4px;
                    background: $white;
                    margin: 0 auto;
                    text-align: center;
                }
            }

        }
    }
}

//Page header background
.culture-head{
    background-image: url(../../../assets/images/common/culture-banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.genepool-head{
    background-image: url(../../../assets/images/common/genepool-banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.faq-head{
    background-image: url(../../../assets/images/common/faq-banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
