.l-preview {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .l-preview {
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
  }
}
