.table {
  display: table;
  width: 100%;
}

.tableRow {
  display: table-row;
}

.tableCell {
  display: table-cell;
}
