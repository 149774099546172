
$mainBackColor: #252f45;

.detwind {
  position: absolute;
  right: 28px;
  top: 0px;
  width: 90%;
  max-width: 950px;
  z-index: 60;
  border-radius: 4px;
  &-info-cont {
    font-size: 0.8rem;
  }
  &-previewImage {
    box-sizing: border-box;
    border-left: 1px solid $mainBackColor;
    border-right: 1px solid $mainBackColor;
    min-height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &-header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-radius: 4px 4px 0 0;
    background-color: #354260;
    color: #a8c6df;
    font-weight: 500;
    font-size: 20.5px;
    &-title {
      display: flex;
      align-items: center;
      span {
        font-size: 15px;
      }
      img {
        width: 40px;
      }
    }
    &-icon {
      width: 45px;
    }
    .disabled-action {
      opacity: 0.5;
    }
  }
  &-main {
    max-height: calc(80vh - 56px);
    background-color: $mainBackColor;
    color: #fff;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
  }
  &-main-cont {
    padding: 14px 20px 14px 17px;
  }
  &-title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 2px solid #6082BA;
    padding-bottom: 5px;
    font-weight: bold;
    &:last-of-type {
      margin-bottom: 15px;
    }
  }
  &-link {
    color: #7a7b7e;
    font-size: x-small;
    font-weight: bold;
    cursor: pointer;
  }
}

.detwind-charts {
  margin: 15px 0;
}

.detwind-chart {
  box-sizing: border-box;
  // display: table-row;
  // height: 100%;
  img {
    width: 40px;
    margin: 2px 10px 2px 0;
  }
  &-title {
    font-size: 1rem;
    .detwind-bar-cont {
      // border-bottom: 1px solid white;
    }
  }
  &-cell {
    display: table-cell;
    height: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    vertical-align: middle;
    img {
      width: 40px;
      margin-right: 10px;
    }
    &:nth-last-child(2) {
      white-space: nowrap;
    }
    &:last-of-type {
      width: 100%;
    }
  }
  &-label {
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
  }
}

.detwind-bar {
  height: 25px;
  font-size: 1.2rem;
  &-left {
    margin-left: 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &-right {
    margin-right: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &-cont {
    display: flex;
    align-items: center;
    margin-left: 15px;
    text-align: center;
    > div {
      box-sizing: border-box;
    }
    &-border {
      border-right: 1px solid white;
    }
  }
}

@media only screen and (max-width: 600px) {
  .detwind {
    position: static;
    width: 100%;
    border-radius: 0px;
    &-header {
      padding: 8px 8px;
      border-radius: 0;
      &-title {
        font-size: smaller;
        span {
          font-size: smaller;
        }
      }
    }
    &-main {
      max-height: unset;
      height: auto;
      overflow-y: unset;
      border-radius: 0;
    }
    &-info-cont {
      font-size: 0.6rem;
    }
    &-chart-title {
      font-size: 0.8rem;
    }
    &-bar {
      &-left {
        margin-left: 5px;
      }
      &-right {
        margin-right: 5px;
      }
      &-cont {
        margin-left: 5px;
      }
      &-cont-single {
        margin-left: 55px;
      }
    }
    &-chart {
      &-label {
        font-size: 12px;
      }
      &-cell img {
        width: 30px;
      }
      &-cell:nth-last-child(2) {
        font-size: smaller;
      }
    }
  }
}
@media only screen and (max-width: 350px) {
  .detwind {
    &-info-cont {
      font-size: 0.7rem;
    }
    &-chart img {
      margin: 2px 5px 2px 0;
    }
  }
}
