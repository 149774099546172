.flex {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &--grow {
    flex: 1;
  }
  &--wrap {
    flex-wrap: wrap;
  }
}

.no-shrink {
  flex-shrink: 0;
}

.row-reverse {
  flex-direction: row-reverse;
}

.align {
    &-baseline {
      align-items: baseline;
    }
    &-center {
      align-items: center;
    }
    &-start {
      align-items: flex-start;
    }
    &-end {
      align-items: flex-end;
    }
    &-self-end {
      align-self: flex-end;
    }
    &-normal {
      align-items: normal;
    }
    &-self {
      &-center {
        align-self: center;
      }
    }
}

.justify {
  &-start {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
  }
  &-end {
    justify-content: flex-end;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
  &-evenly {
    justify-content: space-evenly;
  }
}

.center-childs {
  display: flex;
  align-items: center;
  justify-content: center;
}
