.timeline {
  position: absolute;
  bottom: 50px;
  margin-bottom: 20px;
  margin-left: 50px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 200px);
  z-index: 51;
}

.timeline-bars {
  position: absolute;
  padding: 0 4px;
  left: 0;
  width: calc(100% - 8px);
  display: flex;
  justify-content: space-between;
}

.timeline-bar {
  position: relative;
  width: 1.5px;
  height: 22px;
  background: #fff;
  &:nth-of-type(2n + 1) {
    // display: none;
  }
  &:first-of-type {
    display: block;
  }
  &-label {
    white-space: nowrap;
    position: absolute;
    top: calc(100% + 5px);
    transform: translateX(-50%);
    color: #24354c;
    font-weight: bold;
  }
}

.timeline-slider {
  cursor: pointer;
  position: absolute;
  -webkit-appearance: none;
  width: 100%;
  height: 1.5px;
  padding: 0 10px;
  background: #fff;
  outline: none;
}

.timeline-thumb-label {
  position: absolute;
  top: calc(100% + -7px);
  transform: translateX(-50%);
  color: #24354c;
  font-weight: bold;
}

.timeline-slider::-webkit-slider-thumb {
  pointer-events: all;
  position: relative;
  z-index: 1;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 25px;
  background: #fff;
  border: 2.28px solid #fff;
  border-radius: 10px;
  transition: transform ease-in-out 200ms;
  cursor: pointer;
}

.timeline-slider::-webkit-slider-thumb::after {
  content: "1111";
}

.timeline-slider::-webkit-slider-thumb:hover {
  transform: scale(1.1);
}

.timeline-slider::-moz-range-thumb,
.timeline-slider::-ms-thumb {
  -moz-appearance: none;
  position: relative;
  width: 15px;
  height: 25px;
  background: #fff;
  border: 2.28px solid #fff;
  border-radius: 10px;
  cursor: pointer;
  z-index: 10;
  pointer-events: all;
}

.timeline-slider:focus::-webkit-slider-thumb {
  box-shadow: 0px 0px 20px #fff;
}

.timeline-date {
  position: absolute;
  top: calc(100% + 10px);
  color: #24354c;
  font-weight: bold;
}

.timeline-date-appear {
  color: darkgreen;
}

.timeline-date-disappear {
  color: darkred;
}

.timeline-date-fade {
  animation: fade ease-in-out 300ms 2s forwards;
}

.timeline-pauseplay {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  transform: translateX(95px);
}

.timeline-pause, .timeline-play, .timeline-playfast {
  margin-right: 4px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border: 2px solid #24354c;
  border-radius: 4px;
  cursor: pointer;
}

.timeline-pause::before {
  content: "";
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border-left: 2px solid #24354c;
  border-right: 2px solid #24354c;
}

.timeline-play::before {
  content: "";
  display: block;
  box-sizing: border-box;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #24354c;
}

@keyframes fade {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (max-height: 450px) {
  .timeline {
    bottom: 0px;
    &-date {
      top: calc(100%);
      font-size: 0.7rem;
    }
    &-thumb-label {
      font-size: 0.7rem;
      top: calc(100% + -53px);
    }
    &-bar-label {
      font-size: 0.8rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .timeline {
    flex-direction: column-reverse;
    box-sizing: border-box;
    position: relative;
    bottom: 0;
    margin: 10px auto 25px auto;
    width: 90%;
  }

  .timeline-bar {
    font-size: 12px;
    background-color: #929292;
    &-label {
      top: calc(-80%);
      transform: translateX(-40%);
    }
    &-label-end {
      transform: translateX(-60%);
    }
  }

  .timeline-slider {
    background-color: #929292;
  }
  .timeline-slider, .timeline-thumb-label {
    font-size: 0.7rem;
  }
  .timeline-slider::-webkit-slider-thumb {
    background-color: #929292;
    border-color: #929292;
  }

  .timeline-slider::-moz-range-thumb,
  .timeline-slider::-ms-thumb {
    background-color: #929292;
    border-color: #929292;
  }
  .timeline-pauseplay {
    position: relative;
    display: flex;
    align-items: center;
    transform: none;
    margin-bottom: 64px;
  }
  .timeline-pause, .timeline-play, .timeline-playfast {
    width: 30px;
    height: 30px;
  }

  .timeline-date {
    top: calc(100% + 2px);
    font-size: 0.7rem;
  }
}
