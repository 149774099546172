// $xs-width: 480px;
// $sm-width: 600px;
// $md-width: 768px;
// $lg-width: 1024px;
$xs-width: 480px;
$sm-width: 767px;
$md-width: 991px;
$lg-width: 1200px;


@mixin media-max($size: 300px){
  @media (max-width: #{$size}) {
    @content;
  }
}

@mixin media-min($size: 300px){
  @media (min-width: #{$size}) {
    @content;
  }
}

@mixin media-max-xs {
  @media (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin media-max-sm {
  @media (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin media-max-md {
  @media (max-width: #{$md-width}) {
    @content;
  }
}

@mixin media-max-lg {
  @media (max-width: #{$lg-width}) {
    @content;
  }
}

@mixin media-min-xs {
  @media (min-width: #{$xs-width}) {
    @content;
  }
}

@mixin media-min-sm {
  @media (min-width: #{$sm-width}) {
    @content;
  }
}

@mixin media-min-md {
  @media (min-width: #{$md-width}) {
    @content;
  }
}

@mixin media-min-lg {
  @media (min-width: #{$lg-width}) {
    @content;
  }
}

.show-max {
  display: inherit !important;

  &-md {
    @include media-min-md {
      display: none !important;
    }
  }

  &-sm {
    @include media-min-sm {
      display: none !important;
    }
  }

  &-xs {
    @include media-min-xs {
      display: none !important;
    }
  }

  &-1000 {
    @include media-min(1000px){
      display: none !important;
    }
  }
}

.hide-max {

  &-md {
    @include media-max-md {
      display: none !important;
    }
  }

  &-1000 {
    @include media-max(1000px){
      display: none !important;
    }
  }

  &-sm {
    @include media-max-sm {
      display: none !important;
    }
  }

  &-xs {
    @include media-max-xs {
      display: none !important;
    }
  }
}

.hide-min-md {
  @include media-min-md {
    display: none !important;
  }
}
