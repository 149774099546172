@import 'variables';

.cultres-page {
  .section-text {
    background: #eaeff2;
    @media only screen and(max-width:767px){
      padding: 1rem;
    }
  }

  .section-text-toc {
    li {
      border-bottom: 1px solid $gray-light;
      &:last-child {
        border-bottom: none;
      }
    }
    li a {
      align-items: center;
      justify-content: space-between;
      display: flex;
      img {
        width: 40px;
      }
    }
  }
}
