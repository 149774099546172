
@for $i from 1 through 100 {
  .w#{$i} {
    width: 0% + $i  ;
  }
  .vw#{$i} {
    width: #{$i}vw;
  }
  .max-width#{$i} {
    max-width: 0% + $i ;
  }
}

.width {
	&100 {
		width: 100%;
	}
}
