@import 'variables';

.c-page {
  .page-header-wrap {
    background-color: #305573;
    // min-height: 100px !important;
  }
  h1 {
    // color: black !important;
    span {
      // background-color: black !important;
    }
  }
  a {
    overflow-wrap: anywhere;
    font-size: 14px;
    color: #0eb1d2;
    display: block;
    &:before {
      font-size: 8px;
    }
  }
}
