$color-main: black;
$color-second: #B2b2b2;
$color-third: #1E1E1E;
$color-main-green: #54ae4a;
$white: #fff;
$off-white : #f5f5f5;
$black: #000;
$color-text-light: #a4abb3;
$color-text-dark: #2a2a2a;
$color-background: #ecf5fe;
$gradient-start: #2ab7c9;
$gradient-end: #0c99bd;
$color-light-grey: #acacac;
$status-color-danger: #ca5c54;
$status-color-warning: #e7af5f;
$status-color-success: #74b566;
$color-background-list: #eeefef;
$color-price:#C2964E;
$color-heart:#C2964E;

$default1:#5cccfa;
$default2:#111f39;
$gray : #ddd;
$silver : #b7beca;
$section-bg : #eaeff2;