.loading {
  animation: 1s loader linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.icon-loader {
    &:before,&:after {
        animation: .5s icon-loader linear infinite;
    }
}

@keyframes icon-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
