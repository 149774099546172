
$mainBackColor: #252f45;

.cult {
  &-wind {
    position: absolute;
    right: 28px;
    top: 0px;
    width: 90%;
    z-index: 60;
    border-radius: 4px;
    &-header {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px 8px 20px;
      border-radius: 4px 4px 0 0;
      background-color: #354260;
      color: #a8c6df;
      font-weight: 500;
      font-size: 20.5px;
      &-title {
        display: flex;
        align-items: center;
        span {
          font-size: 15px;
        }
        img {
          width: 40px;
        }
      }
      &-icon {
        width: 45px;
      }
    }
  }
  &-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    max-height: calc(80vh - 56px);
    padding: 14px 20px 14px 17px;
    background-color: $mainBackColor;
    color: #fff;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
    img {
      margin: 3px;
      width: 50px;
      cursor: pointer;
    }
  }
}


@media only screen and (max-width: 600px) {
  .cult {
    &-wind {
      position: static;
      width: 100%;
      border-radius: 0px;
    }
    &-header {
      border-radius: 0;
      &-title {
        font-size: smaller;
        span {
          font-size: smaller;
        }
      }
    }
  }
}
