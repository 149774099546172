@import "~leaflet/dist/leaflet.css"; // sass
@import "~react-leaflet-markercluster/dist/styles.min.css"; // sass

@import "styles/variables";
@import "styles/mixins";
@import "styles/helpers/overflow";
@import "styles/helpers/height";
@import "styles/helpers/width";
@import "styles/helpers/cursor";
@import "styles/helpers/mediaqueries";
@import "styles/helpers/animate";
@import "styles/helpers/flex";
@import "styles/helpers/grid";
@import "styles/helpers/spacing";
@import "styles/helpers/text";
@import "styles/helpers/figure";
@import "styles/helpers/table";
@import "styles/helpers/opacity";
@import "styles/helpers/display";

@import "styles/icofont";
@import "styles/animations";
@import "styles/animate";
@import "styles/map";
@import "styles/culturecomp";
@import "styles/detailsWindow";
@import "styles/cultures-window";
@import "styles/horizontal-bar-chart";
@import "styles/faq-page";
@import "styles/copyrights-page";


//Custom Styles
@import "styles/theme/scss/base";

.gps_ring {
  border: 3px solid red;
  -webkit-border-radius: 40px;
  height: 18px;
  width: 18px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  /*opacity: 0.0*/
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ReactModal__Body--open {
  // overflow: hidden;
  // position: fixed;
  // width: 100%;
  // height: 100%;
}

.ReactModal__Overlay--after-open {
  z-index: 75;
}
.ReactModal__Content--after-open {
  border-radius: 0px !important;
}

.ReactModal__Content {
  padding: 0px !important;
  border: none !important;
  top: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  left: 0px !important;
}
